const SearchIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className || 'w-3 h-3 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 13 12'
    >
      <path d='M11.96 10.864L9.017 7.918a4.912 4.912 0 00-.444-6.47A4.888 4.888 0 005.088 0a4.888 4.888 0 00-3.486 1.449 4.942 4.942 0 000 6.979 4.888 4.888 0 003.486 1.449c1.052 0 2.104-.33 2.976-1.005l2.96 2.93A.658.658 0 0011.5 12a.686.686 0 00.477-.197.672.672 0 00-.017-.939zm-6.856-2.32c-.97 0-1.858-.38-2.548-1.054-1.398-1.4-1.398-3.688 0-5.103a3.578 3.578 0 012.548-1.054c.97 0 1.858.379 2.549 1.054.69.675 1.052 1.58 1.052 2.551 0 .971-.378 1.86-1.052 2.552a3.539 3.539 0 01-2.549 1.053z'></path>
    </svg>
  );
};

function SquareSearchIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='18'
      fill='none'
      viewBox='0 0 20 18'
    >
      <path
        fill='#fff'
        d='M18.857 15.743l-2.802-2.806a4.678 4.678 0 00-.423-6.16 4.655 4.655 0 00-3.32-1.38 4.655 4.655 0 00-3.32 1.38 4.707 4.707 0 000 6.646 4.655 4.655 0 003.32 1.38 4.615 4.615 0 002.834-.957l2.819 2.79a.627.627 0 00.454.19.654.654 0 00.454-.19.64.64 0 00-.016-.893zm-6.53-2.21c-.923 0-1.768-.36-2.426-1.003-1.331-1.333-1.331-3.512 0-4.86a3.408 3.408 0 012.427-1.004c.924 0 1.77.36 2.427 1.004.657.642 1.002 1.505 1.002 2.43 0 .925-.36 1.771-1.002 2.43a3.37 3.37 0 01-2.427 1.003z'
      ></path>
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M1.5 12.641V1.5h15.182v2.773a7.541 7.541 0 011.5 1.331V1.01A1.01 1.01 0 0017.172 0H1.01A1.01 1.01 0 000 1.01v12.121c0 .558.452 1.01 1.01 1.01h4.932a7.453 7.453 0 01-.632-1.5H1.5z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export { SearchIcon, SquareSearchIcon };
